import React from 'react';
import pageStyle from '../../assets/css/page.module.css';
import style from '../../assets/css/main.module.css';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import HeroPage from '../../components/hero.page';
import Hamburger from '../../components/hamburger';
import SimilarCard from '../../components/similar.card';
import SEO from '../../components/seo';

import imgCopy from '../../assets/img/services/copywriting.jpg';
import similar1 from '../../assets/img/services/thumbnail/seo.jpg';
import similar2 from '../../assets/img/services/thumbnail/contenu.jpg';
import similar3 from '../../assets/img/services/thumbnail/pub.jpg';
import similar4 from '../../assets/img/services/thumbnail/developpement.jpg';
import CtaSection from '../../components/cta.section';
import cta from '../../assets/img/illustrations/typewriter.svg';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

export default () => (
  <div id="top">
    <Menu />
    <SEO
      title="Copywriting | Agence digitale | MDS Digital Agency"
      description="Le contenu de votre site internet est un outil à ne pas négliger. MDS est spécialisé dans le copywriting, la rédaction de contenu web."
      keywords="copywriting"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgCopy} />

    <article className={pageStyle.article}>
      <h2>Copywriting/Rédaction pour le web</h2>
      <p>
        Le copywriting de votre site web est un élément très important. Les textes repris sur votre
        site internet ont une importance capitale pour attirer, retenir et fidéliser les visiteurs.
        MDS est spécialisé dans le copywriting, qui est <strong>la rédaction de contenu web</strong>
        . Nous choisissons avec précision et pertinence les mots-clés qui traduiront le mieux
        l’esprit et le savoir-faire de votre entreprise.
      </p>
      <p>Le contenu de votre site internet est un outil à ne pas négliger.</p>
      <p>Il permet à votre site web de performer à deux niveaux:</p>
      <ol>
        <li>
          <p>
            <strong>La visibilité sur les moteurs de recherche</strong>
          </p>
          <p>
            Grâce à un contenu optimisé, votre site internet sera plus visible sur les moteurs de
            recherche tels que <strong>Google</strong>.{' '}
          </p>
          <p>
            Tous les textes que nous rédigeons sont optimisés pour le{' '}
            <AniLink cover bg="#fba515" to="/services/referencement/naturel/">
              référencement naturel (SEO)
            </AniLink>{' '}
            pour permettre d’améliorer <strong>le positionnement de votre site</strong> sur les
            moteurs de recherche et donc d’attirer un plus grand nombre de visiteurs. MDS vous offre
            donc un copywriting très ciblé.
          </p>
        </li>
        <li>
          <p>
            <strong>Votre crédibilité</strong>
          </p>
          <p>
            Plus vos <strong>contenus</strong> seront <strong>impactants</strong> et inciteront à la
            lecture, plus vos visiteurs seront réceptifs à vos messages. MDS s’attache à présenter
            vos produits, vos services et votre entreprise avec <strong>clarté</strong>. Nous
            veillons à placer <strong>vos visiteurs au centre de la stratégie de contenu</strong>, à
            apporter des solutions à leur problématique, à les intéresser et à les mettre en
            confiance. MDS vous fournit un copywriting de qualité supérieure.
          </p>
          <p>
            Une{' '}
            <AniLink cover bg="#fba515" to="/services/mise-a-jour-contenu/">
              mise à jour de contenus
            </AniLink>{' '}
            efficaces sur votre domaine d’activité a pour effet{' '}
            <strong>d’augmenter votre notoriété</strong> et votre crédibilité et de
            <strong> fidéliser votre audience.</strong>
          </p>
        </li>
      </ol>
      <CtaSection
        img={cta}
        ctaText="Devis en ligne"
        title="Sur internet, le contenu est roi !"
        subtitle="Bénéficiez d’une plus grande visibilité sur le web grâce à un contenu optimisé par nos soins."
      />
    </article>
    <div className={pageStyle.footer}>
      <div className={pageStyle.article}>
        <h3>Autres services</h3>
        <div className={pageStyle.footer_flex}>
          <SimilarCard
            text="Référencement naturel (SEO)"
            url="/services/referencement/naturel/"
            img={similar1}
          />
          <SimilarCard
            text="Mise à jour de contenu"
            url="/services/mise-a-jour-contenu/"
            img={similar2}
          />
          <SimilarCard
            text="Référencement payant (SEA)"
            url="/services/referencement/payant/"
            img={similar3}
          />
          <SimilarCard text="Développement" url="/services/developpement/" img={similar4} />
        </div>
      </div>
    </div>

    <Footer />
  </div>
);
